import React, { useRef, useState } from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import LocationBlocks from "./contact/LocationBlocks"
import SEO from "../components/seo"

import { shuffleArray, getPostObj } from "../utils/utils"

import SizzleMp4 from "../videos/sizzle-desktop.mp4"

import PlayPause from "../components/Sizzle/playPause"
import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

import MeetOurDoctors from "./about/MeetOurDoctors"

import Carousel from "../components/carousel/comFeaturedCarousel"
import ReviewQueryCarousel from "../components/carousel/ReviewQueryCarousel"
import ReviewRow from "../components/ReviewRow"
import ReviewRowsSection from "../components/ReviewRowsSection"
import HorizontalTwoColumns from "../components/Bulma/HorizontalTwoColumns"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"

import ClientSocialReviews from "../components/SocialReviews/ClientSocialReviews"
import CTA from "../components/CTA/CTA"
import GetStarted from "../components/CTA/GetStarted"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, graphql } from "gatsby"

import { VideoWrapped } from "../components/reactPlayerTest"
import CursorOverContainer from "../components/cursor/CursorOverContainer"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import OurReviews from "../templates/about/OurReviews"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

const SingleLocation = ({ data, pageContext, location }) => {
  const napRef = useRef()

  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allLocationsJson.nodes[0],
    data.allSpanishLocationsJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  var thisLocation = post

  // Get two random reviews for ReviewRows
  // var reviews = data.allReviewsJson.nodes
  // shuffleArray(reviews)
  // reviews = reviews.slice(0, 5)

  // var reviewRows = reviews.map(review => {
  //   return (
  //     <ReviewRow
  //       key={review.title}
  //       name={review.reviewerName}
  //       quote={review.quote}
  //       url={review.title}
  //       photo={review.thumbnailPublicId}
  //       gender={review.gender}
  //     />
  //   )
  // })

  //   var locationHours =
  //     data.allDataJson.nodes[0].siteItems[0].locations[0].location[0]
  //       .locationHours

  var pageData = thisLocation.pageData
  var locationData = thisLocation.locationData

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          pathname={location.pathname}
          schemaPage={pageData.schemaGeneral ? pageData.schemaGeneral : null}
        />
        <LayoutThree
          sizzle={SizzleMp4}
          language={language}
          pageData={pageData}
          locationData={locationData}
          hearWhatTheyHaveToSay={data.hearWhatTheyHaveToSay.nodes[0]}
          //reviews={data.allReviewsJson.nodes}
          formData={
            data.allLocationsJson.nodes[0].pageData.layout_3.formSection
          }
          napRef={napRef}
        />
      </Layout>
    </SharedStateProvider>
  )
}

// function LayoutTwo(props) {

//   let location = props.thisLocation.pageData.layout_2
//   let reviewRows = props.reviewRows
//   return (
//     <div className="layout-2">
//       <HeadingVideo
//         h1Heading={location.h1Heading}
//         youtube={location.videoOverImage.youtube}
//         imageId={location.videoOverImage.imageId}
//       />
//       <ContactInfo
//         heading={location.topBodyJustText.heading}
//         blurb={location.topBodyJustText.blurb}
//         buttonText={location.topBodyJustText.button.buttonText}
//         href={location.topBodyJustText.button.href}
//         thisLocation={props.thisLocation}
//         language={props.language}
//       />
//       <RatingsReviews
//         reviewsRatingsHeading={location.reviewsRating.reviewsRatingsHeading}
//         east={props.thisLocation.cityZip === "92120"}
//         coastal={props.thisLocation.cityZip === "92117"}
//       />

//       <LocationCarousel
//         language={props.language}
//         reviewCarouselHeading={location.reviewCarouselHeading}
//       />
//       <FeaturedProcedures
//         featuredProcedures={location.featuredProcedures}
//         featuredProceduresHeading={location.featuredProceduresHeading}
//       />
//       <MapSection
//         mapImage={location.mapSection.mapImage}
//         heading={location.mapSection.heading}
//         blurb={location.mapSection.blurb}
//         buttons={location.mapSection.buttons}
//       />

//       <section className="body-section">
//         <div className="columns">
//           <div className="column is-4"></div>
//           <div className="column">
//             <h3>{location.reviewRowsHeading}</h3>
//           </div>
//         </div>
//         {reviewRows}
//       </section>

//       <GetStarted
//         sideColumnIs={4}
//         heading={location.getStarted.heading}
//         paragraph={<p className="large">{location.getStarted.blurb}</p>}
//         buttonUrl={location.getStarted.href}
//         buttonText={location.getStarted.buttonText}
//         centerHeading
//       />
//     </div>
//   )
// }

/* Passes layout prop to all children */
function LayoutWrapper(props) {
  return (
    <div className={props.layout}>
      {React.Children.map(props.children, (child, i) => {
        return React.cloneElement(child, {
          //this properties are available as a props in child components
          layout: props.layout
        })
      })}
    </div>
  )
}

function HeadingVideo(props) {
  if (props.layout === "layout-3") {
    return (
      <>
        <section className="top-section white-back">
          <div className="columns has-text-centered">
            <div className="column is-3"></div>
            <div className="column">
              <h1>{props.h1Heading}</h1>
            </div>
            <div className="column is-3"></div>
          </div>

          <div className="columns">
            <div className="column">
              <CursorOverContainer>
                <div style={{ position: "relative" }}>
                  <VideoWrapped playing={true} />
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=JW1jB1nHy3M`}
                    controls={true}
                    playing={true}
                    overVideo={true}
                    sizzleButton
                    hasWatchVideo
                    onlyButton={true}
                  />
                </div>
              </CursorOverContainer>
            </div>
          </div>
        </section>
      </>
    )
  }
}

function ContactInfo(props) {
  return (
    <section className="body-section contact-info-section">
      <div className="columns">
        <div className="column is-4"></div>

        <div className="column is-10">
          <h2>{props.heading}</h2>
          <p>{props.blurb}</p>
        </div>
        <div className="column is-1"></div>
        <div className="column">
          <LocationBlocks locations={props.thisLocation} />
          {/* <div className="contact-sidebar-block">
                      <h7 className="contact-sidebar-heading">
                      {props.thisLocation.cityName} {props.language === "es" ? "Dirección" : "Address"}
                      </h7>
                      <div className="footer-location-address">
                        {props.thisLocation.streetNumber + " "}
                        {props.thisLocation.streetName}
                        <br />
                        {props.thisLocation.cityName}
                        {", " + props.thisLocation.stateAbbr + " "}
                        {props.thisLocation.cityZip}
                      </p>
                    </div>
                    <div className="contact-sidebar-block">
                      <h7 className="contact-sidebar-heading">{props.language === "es" ? "Teléfono" : "Phone"}</h7>
                      <p className="small">
                        <a href={"tel:+" + props.thisLocation.contactInfo[0].phone}>
                          {props.thisLocation.contactInfo[0].phone}
                        </a>
                      </p>
                    </div> */}

          <div className="contact-sidebar-block">
            <GeneralHours
              language={props.language}
              contact={true}
              locationHours={props.thisLocation.locationHours}
            />
          </div>
          <div className="contact-sidebar-block schedule-appointment">
            <h7 className="contact-sidebar-heading">
              {props.language === "es" ? "Teléfono" : "Schedule Appointment"}
            </h7>
            <p className="small">Request an appointment today</p>
            <Button
              standalone
              buttonText={props.buttonText}
              href={props.href}
            />
          </div>
        </div>

        <div className="column is-4"></div>
      </div>
    </section>
  )
}

// Picks google reviews based on matching zipcode
function RatingsReviews(props) {
  return (
    <section className="body-section location-rating-reviews color-back">
      <div className="columns has-text-centered">
        <div className="column is-5"></div>
        <div className="column">
          <h3>{props.reviewsRatingsHeading}</h3>
        </div>
        <div className="column is-5"></div>
      </div>
      <div className="columns">
        <div className="column is-5"></div>
        <div className="column">
          <div className="rating-reviews">
            <ClientSocialReviews
              google
              facebook
              east={props.east}
              coastal={props.coastal}
              type={props.socialReviewsType}
            />
          </div>
        </div>
        <div className="column is-5"></div>
      </div>
    </section>
  )
}

function LocationCarousel(props) {
  return (
    <section className="body-section location-carousel">
      <div className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <h3>
            {props.language === "es"
              ? "Escuche de Nuestros Pacientes"
              : props.reviewCarouselHeading}
          </h3>
        </div>
      </div>

      <Carousel slideID="featuredCarousel" language={props.language} />
      <div className="columns has-text-centered">
        <div className="column">
          <Button contained buttonText="More Reviews" href="/video-review/" />
        </div>
      </div>
    </section>
  )
}

function FeaturedProcedures(props) {
  return (
    <section className="body-section featured-procedures-section">
      <div className="columns color-back">
        <div className="column is-3"></div>
        <div className="column">
          {props.featuredProceduresHeading ? (
            <h2>{props.featuredProceduresHeading}</h2>
          ) : (
            <h2>Procedures at Our {props.cityName} Office</h2>
          )}
          {props.featuredProceduresBlurb ? (
            <MarkdownViewer
              className="mb-0"
              markdown={props.featuredProceduresBlurb}
            />
          ) : (
            <p className="mb-0">
              We offer a wide range of oral and maxillofacial surgery
              procedures. To view all of our services, please visit our{" "}
              <a href="/procedure/" title="View all of our procedures">
                oral surgery page
              </a>
              .
            </p>
          )}
        </div>
        <div className="column is-3"></div>
      </div>
      {props.featuredProcedures.map((item, i) => {
        let featuredProcedure = item

        //let reviews = getXRandomElements(2, getRelatedReviews(props.reviews));

        // Helper functions
        // function getRelatedReviews(allReviews) {
        //   let reviews = [];
        //   for (let i = 0; i < allReviews.length; i++) {
        //     if (allReviews[i].mainProcedureDone === item.procedureName) reviews.push(allReviews[i])
        //   }
        //   return reviews
        // }
        // function getXRandomElements(x, elements) {
        //   let elementsCopy = elements
        //   shuffleArray(elementsCopy)
        //   elementsCopy = elementsCopy.slice(0, x)
        //   return elementsCopy;
        // }

        let colorBack = false

        if (i % 2 === 0 && props.layout === "layout-3") colorBack = true

        var featuredProcedureClasses = classNames(
          "columns is-vcentered featured-procedure joshua-tree-content",
          {
            "reverse-rows": i % 2 === 1 && props.layout === "layout-2",
            "color-back": colorBack
          }
        )

        return (
          <>
            <div
              className={featuredProcedureClasses}
              key={featuredProcedure.heading}
            >
              <div className="column is-2"></div>
              <div className="column">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={featuredProcedure.imageId}
                  width="auto"
                  responsive
                />
              </div>
              <div className="column is-2"></div>
              <div className="column bottom-col-mobile">
                <h3>{featuredProcedure.heading}</h3>
                <MarkdownViewer markdown={featuredProcedure.text} />
                {/* <ButtonGroupMap hyperlink buttons={featuredProcedure.buttons} /> */}
                <FeaturedProcedureButtons
                  buttons={featuredProcedure.buttons}
                  napRef={props.napRef}
                />
              </div>
              <div className="column is-3"></div>
            </div>
            {/* <FeaturedProcedureReviews colorBack={colorBack} heading={featuredProcedure.reviewsHeading} reviews={reviews} /> */}
          </>
        )
      })}
    </section>
  )
}

function FeaturedProcedureReviews(props) {
  return (
    <div
      className={`review-row-heading ${props.colorBack ? "color-back" : ""}`}
    >
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column">
          <h5>{props.heading}</h5>
        </div>
      </div>
      <div
        className={`columns review-row-content ${
          props.colorBack ? "color-back" : ""
        }`}
      >
        <div className="column is-2"></div>
        {props.reviews.map((review, j) => {
          let title = review.title
          if (title.charAt(0) !== "/") title = "/" + title
          return (
            <>
              <div className="column is-2 review-photo-and-caption">
                <Link to={title}>
                  <ImageMeta
                    publicId={review.thumbnailPublicId}
                    fetchFormat="png"
                    cloudName="nuvolum"
                    width="200"
                    height="200"
                    gravity="face"
                    radius="max"
                    crop="thumb"
                    className="review-row-photo"
                    noLazyload
                  />
                  <h6 className="image-caption staff staff-title">
                    Meet {review.reviewerName}
                  </h6>
                </Link>
              </div>
              <div
                className={`column mobile-row-p ${
                  j === 0 ? "padding-bottom-mobile" : ""
                }`}
              >
                <p>&quot;{review.shortQuote}&quot;</p>
              </div>
              {j === 0 && <div className="column is-2"></div>}
            </>
          )
        })}
        <div className="column is-2"></div>
      </div>
    </div>
  )
}

const LocationDetails = ({ location, data, napRef }) => {
  return (
    <div className="columns location-details">
      <div className="column is-2"></div>

      <div className="column">
        <div className="details-blurb">
          <MarkdownViewer markdown={data.text} />
        </div>

        <div className="nap-list">
          {data.listOne && <MarkdownViewer markdown={data.listOne} />}
          {data.listTwo && <MarkdownViewer markdown={data.listTwo} />}
        </div>
      </div>

      <div className="column is-2"></div>

      <div className="column nap-block">
        <h3 id="nap" ref={napRef}>
          {data.addressHeading}
        </h3>

        <div className="nap-info">
          {!data.showAddress ? (
            ""
          ) : (
            <>
              <a
                href={location.googlePlaceLink}
                target="_blank"
                title={`Get directions to our ${location.locationName} location.`}
              >
                {`${location.streetNumber} ${location.streetName}`}
                <div className="address-spacer"></div>
                {`${location.cityName}, ${location.stateAbbr} ${location.cityZip}`}
              </a>
            </>
          )}

          {!data.showPhone ? (
            ""
          ) : (
            <>
              <a href={`tel:+1${location.phone}`} title="Give us a call">
                {location.phone}
              </a>
            </>
          )}

          {!data.showEmail ? (
            ""
          ) : (
            <>
              <a href={`mailto:${location.email}`} title="Email us">
                {location.email}
              </a>
            </>
          )}

          {!data.imageId ? (
            ""
          ) : (
            <ImageMeta publicId={data.imageId} cloudName="nuvolum" />
          )}

          <GeneralHours
            language="en"
            contact={true}
            locationHours={location.locationHours}
          />
        </div>
      </div>

      <div className="column is-2"></div>
    </div>
  )
}

function FormSection({ form, napRef }) {
  const toCamelCase = string => {
    let out = ""
    string.split(" ").forEach((el, idx) => {
      let add = el.toLowerCase()
      out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1)
    })
    return out
  }

  if (form.hasForm) {
    return (
      <div className="columns location-contact-form">
        <div className="column is-4"></div>

        <div className="column">
          <center>
            <div style={{ maxWidth: "1000px" }}>
              <div id="form">
                {form.heading && <h3>{form.heading}</h3>}
                <p className="required-fields">
                  <span className="required-asterisk">*</span>
                  Required Fields
                </p>
              </div>
              <Seamless
                src={`https://secureform.seamlessdocs.com/f/${form.seamlessShortcode}?embedded=true`}
              />
            </div>
          </center>
        </div>

        <div className="column is-4"></div>
      </div>
    )
  } else {
    return ""
  }
}

function MapSection(props) {
  if (props.layout === "layout-3") {
    return (
      <section className="body-section">
        <div className="columns has-text-centered">
          <div className="column location-map-heading">
            <h3>{props.heading}</h3>
          </div>
        </div>
        <div className="columns location-map-image">
          <div className="column">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.mapImage}
              width="auto"
              responsive
            />
          </div>
        </div>
        <div className="columns has-text-centered">
          <div className="column is-4"></div>
          <div className="column">
            <p className="large">{props.blurb}</p>
          </div>
          <div className="column is-4"></div>
        </div>
        <div className="columns location-map-bottom">
          <div className="column">
            <ButtonGroupMap isCentered buttons={props.buttons} />
          </div>
        </div>
      </section>
    )
  }
  return (
    <section className="body-section">
      <div className="columns">
        <div className="column">
          <ImageMeta
            cloudName="nuvolum"
            publicId={props.mapImage}
            width="auto"
            responsive
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-4"></div>
        <div className="column">
          <h3>{props.heading}</h3>
          <p className="large">{props.blurb}</p>
        </div>
        <div className="column is-4"></div>
      </div>
      <div className="columns">
        <div className="column">
          <ButtonGroup isCentered>
            {props.buttons.map(item => {
              let button = item.button
              return (
                <Button
                  key={button.href}
                  buttonText={button.buttonText}
                  href={button.href}
                />
              )
            })}
          </ButtonGroup>
        </div>
      </div>
    </section>
  )
}

function LayoutThree(props) {
  let data = { ...props }

  return (
    /* Passes layout prop to all children */
    <LayoutWrapper layout={data.pageData.layout}>
      <SizzleHeadingButtons
        language={props.language}
        cldSizzle={props.sizzle}
        heading={data.pageData.layout_3.sizzleHeadingButtons.heading}
        buttons={data.pageData.layout_3.sizzleHeadingButtons.buttons}
      />
      <TextImage
        className="is-vcentered text-image color-back"
        text={data.pageData.layout_3.textImage.text}
        imageId={data.pageData.layout_3.textImage.imageId}
      />
      {/* <LocationCarousel
        language={props.language}
        reviewCarouselHeading={data.pageData.layout_3.reviewCarouselHeading}
      /> */}

      {/*Borrowed from About Us page */}
      <OurReviews post={props.hearWhatTheyHaveToSay} />

      <FeaturedProcedures
        featuredProcedures={data.pageData.layout_3.featuredProcedures}
        featuredProceduresHeading={
          data.pageData.layout_3.featuredProceduresHeading
        }
        //reviews={props.reviews}
        featuredProceduresBlurb={data.pageData.layout_3.featuredProceduresBlurb}
        napRef={props.napRef}
        cityName={data.locationData.cityName}
      />

      <LocationDetails
        location={data.locationData}
        data={data.pageData.layout_3.locationDetails}
        napRef={props.napRef}
      />

      <FormSection form={data.pageData.layout_3.formSection} />

      <MapSection
        mapImage={data.pageData.layout_3.mapSection.mapImage}
        heading={data.pageData.layout_3.mapSection.heading}
        blurb={data.pageData.layout_3.mapSection.blurb}
        buttons={data.pageData.layout_3.mapSection.buttons}
      />
      {/* <RatingsReviews
        reviewsRatingsHeading={data.pageData.layout_3.reviewsRating.reviewsRatingsHeading}
        east={data.locationData.cityZip === "92120"}
        coastal={data.locationData.cityZip === "92117"}
        socialReviewsType={"big-rating"}
      /> */}
      <AllRatingsReviews
        heading="Our Ratings and Reviews From Patients"
        buttonHref="/video-review/"
        buttonText="More Reviews"
        colorBack
      />
      {/* <GetStarted
        className="single-location-getting-started"
        sideColumnIs={4}
        heading={data.pageData.layout_3.getStarted.heading}
        paragraph={<p className="large">{data.pageData.layout_3.getStarted.blurb}</p>}
        buttonUrl={data.pageData.layout_3.getStarted.href}
        buttonText={data.pageData.layout_3.getStarted.buttonText}
        centerHeading
      /> */}
    </LayoutWrapper>
  )
}

function SizzleHeadingButtons(props) {
  const vidRef = useRef()
  const [playing, setPlaying] = useState(true)

  function handlePlayPause() {
    setPlaying(!playing)

    if (playing) {
      vidRef.current.pause()
      setPlaying(false)
    } else {
      vidRef.current.play()
      setPlaying(true)
    }
  }

  return (
    <HorizontalTwoColumns
      style={{ paddingTop: "88px" }}
      className="is-vcentered sizzle-heading-buttons"
      leftColWidth={0}
      middleColWidth={1}
      rightColWidth={2}
      leftColumn={
        <div className="column is-12 sizzle">
          {/* <ReactPlayer
            url={`https://res.cloudinary.com/nuvolum/video/upload/f_auto,q_auto:good/v1/${props.cldSizzle}`}
            width="100%"
            height=""
            playing={playing}
            playsinline
            loop={true}
            muted
            controls={false}
          /> */}
          <video
            ref={vidRef}
            width="100%"
            height=""
            playsInline
            loop
            muted
            autoPlay
            controls={false}
          >
            <source src={props.cldSizzle} type="video/mp4" />
          </video>
          <PlayPause
            language={props.language ? props.language : null}
            handlePlayPause={handlePlayPause}
            videoPlaying={playing}
          />
        </div>
      }
      rightColumn={
        <div className="column bottom-col-mobile location-sizzle-heading">
          <h1>{props.heading}</h1>
          <ButtonGroupMap isCentered buttons={props.buttons} />
        </div>
      }
    />
  )
}

function TextImage(props) {
  return (
    <HorizontalTwoColumns
      style={{ paddingTop: "88px" }}
      className={props.className}
      leftColWidth={2}
      middleColWidth={1}
      rightColWidth={2}
      leftColumn={
        <MarkdownViewer
          className="column is-9 location-text-image"
          markdown={props.text}
        />
      }
      rightColumn={
        <div className="column">
          <ImageMeta
            cloudName="nuvolum"
            publicId={props.imageId}
            width="auto"
            responsive
          />
        </div>
      }
    />
  )
}

function FeaturedProcedureButtons({ buttons, napRef }) {
  const scrollToElement = id => {
    //napRef.current.scrollIntoView(true, {behavior: "smooth"})
    window.scroll({
      top: napRef.current.offsetTop - 88,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <ButtonGroup>
      {buttons &&
        buttons.map((btn, i) => (
          <>
            {btn.button.href[0] === "#" ? (
              <a
                className="standard-button contained"
                onClick={() => scrollToElement()}
              >
                {btn.button.buttonText}
              </a>
            ) : (
              <Button
                contained={btn.button.appearance === "contained"}
                minimal={btn.button.appearance === "minimal"}
                external={btn.button.destination === "external"}
                tel={btn.button.destination === "tel"}
                key={btn.button.href}
                buttonText={btn.button.buttonText}
                href={btn.button.href}
              />
            )}
          </>
        ))}
    </ButtonGroup>
  )
}

export const pageQuery = graphql`
  query SingleLocation($title: String!) {
    # allReviewsJson(
    #   filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    # ) {
    #   nodes {
    #     id
    #     title
    #     metaTitle
    #     metaDescription
    #     mainProcedureDone
    #     proceduresDone {
    #       procedureDone {
    #         procedureName
    #         procedureUrl
    #       }
    #     }
    #     heading
    #     quote
    #     shortQuote
    #     reviewLanguage
    #     reviewType
    #     reviewerName
    #     subheading
    #     thumbnailPublicId
    #     mainPhotoPublicId
    #     youtube
    #     monthYear {
    #       month
    #       year
    #     }
    #   }
    # }
    allLocationsJson(filter: { pageData: { title: { eq: $title } } }) {
      nodes {
        pageData {
          metaTitle
          metaDescription
          searchHeading
          title
          schemaGeneral
          layout
          layout_3 {
            ...layoutThreeFields
            featuredProceduresBlurb
            locationDetails {
              addressHeading
              imageId
              listOne
              listTwo
              showAddress
              showEmail
              showPhone
              text
            }
            formSection {
              hasForm
              heading
              locationForm
              seamlessShortcode
            }
          }
        }
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
        }
      }
    }
    allSpanishLocationsJson(filter: { pageData: { title: { eq: $title } } }) {
      nodes {
        pageData {
          metaTitle
          metaDescription
          searchHeading
          title
          layout
          layout_3 {
            ...layoutThreeFieldsEs
          }
        }
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
        }
      }
    }
    hearWhatTheyHaveToSay: allUniquePagesJson(
      filter: { hearWhatTheyHaveToSay: { heading: { ne: null } } }
    ) {
      nodes {
        hearWhatTheyHaveToSay {
          heading
          videos {
            patients {
              imageId
              youtube
              caption
            }
            referringProviders {
              imageId
              youtube
              caption
            }
          }
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "about-us/" } }) {
      nodes {
        meetOurDoctors {
          images {
            doctor {
              caption
              imageId
              url
            }
          }
        }
      }
    }
  }
`

export default SingleLocation
